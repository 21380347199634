<template>
    <SimpleTable formclass="ExpertStore/form" endpoint="expert-stores" :aditionalColumns="headers" :hideNew="!isAdmin">
        <template>
            <h1>Associação: Lojas -> Especialistas</h1>
            <v-divider class="mb-4" />
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
import { mapGetters } from "vuex";

export default {
    components: {
        SimpleTable,
    },

    data() {
        return {
            isAdmin: false,
            headers: [
                { text: this.$t("Email"), value: "expert.email", align: "right", sortable: true },
                { text: this.$t("Nome"), value: "expert.name", align: "right", sortable: true },
            ],
        };
    },

    mounted() {
        this.isAdmin = this.verifyRole("ADMIN");
    },

    computed: {
        ...mapGetters(["verifyRole"]),
    },
};
</script>